import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import { getSearchParams } from "gatsby-query-params"
import Layout from "../../Components/Layout";
import peacePC from "../../assets/images/zenklai.png";
import { RaisedButton } from "../../Components/Buttons";
import _fetch from "../../utils/_fetch";

import { PDFDocument, PDFName, PDFDict } from 'pdf-lib';
//import { Document, Page } from 'react-pdf-with-legacy-pdfjs';
//import { Document, Page } from 'react-pdf-js';

//import { Document, Page } from 'react-pdf';

import { usePdf } from 'react-pdf-js';
//  const pdfUrl = '../../../static/2024EEDiplomas.pdf'; // Provide the URL to your PDF file

const MyPdfViewer = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
 
  const renderPagination = (page, pages) => {
    if (!pages) {
      return null;
    }
    let previousButton = <li className="previous" onClick={() => setPage(page - 1)}><a href="#"><i className="fa fa-arrow-left"></i> Previous</a></li>;
    if (page === 1) {
      previousButton = <li className="previous disabled"><a href="#"><i className="fa fa-arrow-left"></i> Previous</a></li>;
    }
    let nextButton = <li className="next" onClick={() => setPage(page + 1)}><a href="#">Next <i className="fa fa-arrow-right"></i></a></li>;
    if (page === pages) {
      nextButton = <li className="next disabled"><a href="#">Next <i className="fa fa-arrow-right"></i></a></li>;
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  }
 
  const canvasEl = useRef(null);
 
  const [loading, numPages] = usePdf({
    file: '../../2024EEDiplomas.pdf',
    page,
    canvasEl
  });
 
  useEffect(() => {
    setPages(numPages);
  }, [numPages]);
 
  return (
    <div>
      {loading && <span>Loading...</span>}
      <canvas ref={canvasEl} />
    </div>
  );
}
 
export default MyPdfViewer;