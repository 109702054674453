import axios from "axios"

export default (uri, method, data, secure = false) => {
  const params = {
    method,
  }

  if (secure) {
    const token = localStorage.getItem("token")

    params.headers = {
      Authorization: `Bearer ${token}`,
    }
  }

  if (data) {
    params.data = new URLSearchParams({
      ...data,
    }).toString()
  }

  return axios({
    url: `https://europosegzaminas.lrt.lt/euegzaminas/EuApi/${uri}`,
    ...params,
  })
}